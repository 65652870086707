:root {
  --tabs-width: 240px;
}

@media (max-width: 991.98px) {
  :root {
    --tabs-width: 80px;
  }
}
@media (max-width: 991.98px) {
  main {
    margin-bottom: 50px;
  }
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  position: relative;
}
.main-content .label {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  color: #525d6f;
  opacity: 0.4;
  transition: opacity 0.4s ease-in-out;
  display: block;
  width: calc(100% - 48px);
  text-align: right;
  z-index: 100;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 15px 0;
  position: relative;
}
.main-content .active-label::after {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  background: #198754;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 50%;
}
.main-content input[type=radio] {
  display: none;
  width: 0;
}
.main-content .label:hover,
.main-content input[type=radio]:checked + .label {
  opacity: 1;
}
.main-content .popup {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-sizing: border-box;
  border: 16px solid #ebf0f4;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 991.98px) {
  .main-content .popup {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border: 8px solid #ebf0f4 !important;
  }
}
.main-content .content-block {
  position: absolute;
  top: 0;
  z-index: 10;
  left: var(--tabs-width);
  padding: 20px;
  height: 618px;
  overflow-y: auto;
  width: calc(100% - var(--tabs-width));
}
.main-content .prev-next-block {
  position: absolute;
  z-index: 10;
  left: var(--tabs-width);
  padding: 0 20px;
  height: 80px;
  width: calc(100% - var(--tabs-width));
  bottom: 0;
  border-top: 1px solid #ebf0f4;
  background-color: #ffffff;
}
.main-content .prev-next-block .buttons {
  box-shadow: none !important;
  transition: 0.3s;
}
.main-content .prev-next-block .buttons:hover {
  transition: 0.3s;
  transform: scale(1.02);
}
.main-content .prev-next-block .prev-next-button {
  background-color: #ebf0f4;
  border-color: #ebf0f4;
  color: #525d6f;
}
.main-content .tabs {
  width: 100%;
  max-width: var(--tabs-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: anchor-center;
}
.main-content .marker {
  position: absolute;
  width: 100%;
  height: 200%;
  display: flex;
  flex-direction: column;
  top: -100%;
  left: 0;
  transition: transform 0.2s ease-in-out;
}
.main-content .marker #bottom,
.main-content .marker #top {
  background-color: #ebf0f4;
  box-shadow: 4px 3px 15px rgba(46, 54, 67, 0.08);
}
.main-content .marker #top {
  height: 50%;
  margin-bottom: auto;
  border-radius: 0 0 32px 0;
}
.main-content .marker #bottom {
  height: calc(50% - 60px);
  border-radius: 0 32px 0 0;
}
.main-content #tab1:checked ~ .marker {
  transform: translateY(0%);
}
.main-content #tab2:checked ~ .marker {
  transform: translateY(4.5%);
}
@media (max-width: 991.98px) {
  .main-content #tab2:checked ~ .marker {
    transform: translateY(4.7%);
  }
}
.main-content #tab3:checked ~ .marker {
  transform: translateY(9%);
}
@media (max-width: 991.98px) {
  .main-content #tab3:checked ~ .marker {
    transform: translateY(9.5%);
  }
}
.main-content #tab4:checked ~ .marker {
  transform: translateY(13.5%);
}
@media (max-width: 991.98px) {
  .main-content #tab4:checked ~ .marker {
    transform: translateY(14.3%);
  }
}
.main-content .transaction-expire-block {
  position: absolute;
  top: -28px;
  right: 40px;
}
.main-content .transaction-expire-block small {
  font-weight: bold;
}
.main-content .selected-item {
  background-color: #ebf0f4;
}

.main-information {
  max-height: 700px;
  height: 700px;
  border: 16px solid #ebf0f4;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-right: 0;
  position: relative;
  overflow: hidden;
}
.main-information .main-information-item {
  padding: 30px;
  background: #ffffff;
  overflow-y: auto;
  height: calc(100% - 50px);
}
@media (max-width: 991.98px) {
  .main-information {
    border: 8px solid #ebf0f4;
    border-radius: 40px;
    margin-bottom: 40px;
  }
}

.info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
}
.info-block .dotes {
  border-bottom: 1px dashed #525d6f;
  opacity: 0.8;
}

.title-block strong {
  font-size: 20px;
}

.cx-orders-container .cx-order-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  flex-wrap: wrap;
  border: 1px solid #ebf0f4;
}
.cx-orders-container .cx-order-wrapper img {
  width: 100px;
  height: auto;
  flex: 1;
}
.cx-orders-container .cx-order-wrapper .cx-order-desction {
  margin: 0 10px;
  font-size: 14px;
  font-style: italic;
  flex: 5;
}
.cx-orders-container .cx-order-wrapper .cx-order-price {
  flex: 1;
}

@media (max-width: 991.98px) {
  .wallet-info-text {
    font-size: 14px;
  }
}

.cancel-payment-block {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid #ebf0f4;
  height: 50px;
  background-color: #ffffff;
}

.btn-primary {
  background-color: #0533b3 !important;
  border-color: #0533b3 !important;
}

.modal.fade .modal-dialog {
  transform: translate(0, -50px) !important;
  transition: transform 0.5s cubic-bezier(0.39, 0.58, 0.57, 1) !important;
}

.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}/*# sourceMappingURL=new_design.css.map */