// html,
// body {
//   height: 100%;
//   width: 100%;
//   overflow: hidden;
//   background-color: #d3dce6;
//   font-family: monospace;
// }

$block-width: 700px;
$tabs-width: 240px;

:root {
  --tabs-width: #{$tabs-width};
}

@media (max-width: 991.98px) {
  :root {
    --tabs-width: 80px;
  }
}

main {
  @media (max-width: 991.98px) {
    margin-bottom: 50px;
  }
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $block-width;
  position: relative;

  .label {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    color: #525d6f;
    opacity: .4;
    transition: opacity .4s ease-in-out;
    display: block;
    width: calc(100% - 48px);
    text-align: right;
    z-index: 100;
    user-select: none;
    padding: 15px 0;
    position: relative;

  }

  .active-label {
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      background: #198754;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border-radius: 50%;
    }
  }

  input[type="radio"] {
    display: none;
    width: 0;
  }

  .label:hover,
  input[type="radio"]:checked+.label {
    opacity: 1;
  }

  .popup {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-sizing: border-box;
    border: 16px solid #ebf0f4;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @media (max-width: 991.98px) {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border: 8px solid #ebf0f4 !important;
    }
  }

  .content-block {
    position: absolute;
    top: 0;
    z-index: 10;
    left: var(--tabs-width);
    padding: 20px;
    height: calc($block-width - 82px);
    overflow-y: auto;
    width: calc(100% - var(--tabs-width));
  }

  .prev-next-block {
    position: absolute;
    z-index: 10;
    left: var(--tabs-width);
    padding: 0 20px;
    height: 80px;
    width: calc(100% - var(--tabs-width));
    bottom: 0;
    border-top: 1px solid #ebf0f4;
    background-color: #ffffff;

    .buttons {

      box-shadow: none !important;

      transition: .3s;

      &:hover {
        transition: .3s;
        transform: scale(1.02);
      }
    }

    .prev-next-button {
      background-color: #ebf0f4;
      border-color: #ebf0f4;
      color: #525d6f;
    }
  }

  .tabs {
    width: 100%;
    max-width: var(--tabs-width);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: anchor-center;
  }

  .marker {
    position: absolute;
    width: 100%;
    height: 200%;
    display: flex;
    flex-direction: column;
    top: calc(-100%);
    left: 0;
    transition: transform .2s ease-in-out;
  }

  .marker #bottom,
  .marker #top {
    background-color: #ebf0f4;
    // box-shadow: 32px 32px 48px #2e364315;
    box-shadow: 4px 3px 15px rgb(46 54 67 / 8%)
  }

  .marker #top {
    height: calc(50%);
    margin-bottom: auto;
    border-radius: 0 0 32px 0;
  }

  .marker #bottom {
    height: calc(50% - 60px);
    border-radius: 0 32px 0 0;
  }

  #tab1:checked~.marker {
    // transform: translateY(calc(calc(50% / 4) * 0));
    transform: translateY(0%);
  }

  #tab2:checked~.marker {
    transform: translateY(4.5%);

    @media (max-width: 991.98px) {
      transform: translateY(4.7%);
    }
  }

  #tab3:checked~.marker {
    transform: translateY(9%);

    @media (max-width: 991.98px) {
      transform: translateY(9.5%);
    }
  }

  #tab4:checked~.marker {
    transform: translateY(13.5%);

    @media (max-width: 991.98px) {
      transform: translateY(14.3%);
    }
  }

  .transaction-expire-block {
    position: absolute;
    top: -28px;
    right: 40px;

    small {
      font-weight: bold;
    }
  }

  .selected-item {
    background-color: #ebf0f4;
  }


}

.main-information {
  max-height: 700px;
  height: 700px;
  border: 16px solid #ebf0f4;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-right: 0;
  position: relative;
  overflow: hidden;

  .main-information-item {
    padding: 30px;
    background: #ffffff;
    overflow-y: auto;
    height: calc(100% - 50px);
  }

  @media (max-width: 991.98px) {
    border: 8px solid #ebf0f4;
    border-radius: 40px;
    margin-bottom: 40px;
  }
}

.info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;

  .dotes {
    border-bottom: 1px dashed #525d6f;
    opacity: 0.8;
  }
}

.title-block {
  strong {
    font-size: 20px;
  }
}

.cx-orders-container {
  .cx-order-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    // box-shadow: 2px 2px 5px rgba($color: $black-color, $alpha: 0.2);
    flex-wrap: wrap;
    border: 1px solid #ebf0f4;

    img {
      width: 100px;
      height: auto;
      flex: 1;
    }

    .cx-order-desction {
      margin: 0 10px;
      font-size: 14px;
      font-style: italic;
      flex: 5;
    }

    .cx-order-price {
      flex: 1
    }
  }
}

.wallet-info-text {
  @media (max-width: 991.98px) {
    font-size: 14px;
  }
}

.cancel-payment-block {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid #ebf0f4;
  height: 50px;
  background-color: #ffffff;
}

.btn-primary {
  background-color: #0533b3 !important;
  border-color: #0533b3 !important;
}

.modal.fade .modal-dialog {
  transform: translate(0, -50px) !important;
  transition: transform 0.5s cubic-bezier(0.39, 0.58, 0.57, 1) !important;
}

.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}


// /* Плавное опускание модального окна */
// .modal.fade .modal-dialog {
//   transform: translateY(-100%);
//   /* Начальная позиция: за верхней границей экрана */
//   transition: transform 0.5s ease-out;
//   /* Плавное движение */
// }

// /* Когда модальное окно открыто (классы .show), окно опускается */
// .modal.show .modal-dialog {
//   transform: translateY(0);
//   /* Конечная позиция: на экране */
// }