@import 'variable';

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a {
    color: $black-color;

    &:hover {
        opacity: 0.5;
    }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"] {
    padding: 10px 15px;
    // border: none;
    // box-shadow: 2px 2px 3px rgba($color: $black-color, $alpha: 0.1);
    border-radius: $border-radius-10;
    background-color: $dark-color-100;
    // font-style: italic;
    width: 100%;
    background-color: $white-color !important;

    &:focus {
        box-shadow: none;
    }
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
    padding: 10px 50px;
    background-color: $secondary-color;
    color: $white-color;
    border-radius: $border-radius-10;
    border: none;
    font-size: $font-size-18;
    text-transform: capitalize;
    cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.veriff-modal-select-option {
    // box-shadow: 2px 2px 3px rgba($color: $black-color, $alpha: 0.1);
    border-radius: $border-radius-10;
    // font-style: italic;
    width: 100%;
    height: 46px;
}

.capitalize {
    text-transform: capitalize;
}

.word-break-break-word {
    word-break: break-all;
}

.cx-cancel-btn {
    text-align: center;
    cursor: pointer;
    color: $white-color;

    button {
        color: $white-color;
        background: none !important;
        border: none;
        padding: 0 !important;
        font-family: arial, sans-serif;
        color: $white-color;
        text-decoration: underline;
        cursor: pointer;
    }
}

.err {
    background-color: $white-color;
    color: $danger-color;
    font-size: large;
    padding: 20px 20px !important;
    text-align: center;
}

.cx--container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .cx--container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .cx--container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .cx--container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .cx--container {
        max-width: 1140px;
    }
}

.cx--header {
    display: flex;
    align-items: center;
    justify-content: center;

    .cx--logo-wrapper {
        margin-top: 35px;

        img {
            max-height: 35px;
        }
    }
}

.custom-header {
    // box-shadow: 0 3px 15px rgb(46 54 67 / 8%);
    background-color: #ebf0f4;

    @media (max-width: 991.98px) {
        font-size: 14px;
    }
}

.cx--gray-background {
    background-color: $body-color-100;
}

.cx--primary-background {
    background-color: $primary-color;
}

.cx--dark-background {
    background-color: $dark-color;
}

.cx--white-background {
    background-color: $white-color;
}


.cx-accordion-body-list {
    list-style: none;
    display: flex;
    overflow: auto;
    align-items: center;
    padding: 0;
    margin: 0;

    .cx-accordion-list-item {
        text-align: center;
        flex: 1;
        padding: 10px;
        border-radius: $border-radius-10;
        // cursor: pointer;
        transition: 0.3s;
        margin: 5px;
        max-width: 120px;

        img {
            width: 40px;
            margin-bottom: 10px;
        }

        .cx-accordion-list-item-name {
            font-size: $font-size-12;
            font-weight: 600;

            span {
                display: block;
                font-weight: 100;
            }
        }

        // &:hover {
        //     background-color: $dark-selected-color;
        // }
    }

    // .cx-accordion-body-number-item:not(:nth-child(5)) {
    //     padding: 5px 5px;
    //     margin: 3px;
    //     background-color: $white-color;
    //     border-radius: $border-radius-5;
    //     box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 40%);

    //     input {
    //         border: none;
    //         text-align: center;
    //         width: 30px;
    //         font-weight: 600;
    //         font-size: $font-size-22;
    //         color: $black-color;

    //         &:focus {
    //             outline: none;
    //         }
    //     }
    // }

    // .cx-accordion-body-number-item:nth-child(5) {
    //     margin: 0 10px;
    //     font-weight: 800;
    //     font-size: $font-size-20;
    // }

    .cx-accordion-body-number-item {
        // padding: 5px;
        // margin: 3px;
        // background-color: $white-color;
        border-radius: $border-radius-5;
        // box-shadow: inset 2px 2px 5px rgba($color: $black-color, $alpha: 0.3);

        div {
            flex-wrap: wrap;

            input {
                // border: none;
                text-align: center;
                width: 35px !important;
                font-weight: 600;
                font-size: $font-size-22;
                color: $black-color;
                border-radius: $border-radius-5;



                box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
                border: 1px solid #ced4da;

                &:focus {
                    outline: none;
                }
            }

            // @media screen and (max-width: 575.98px) {
            //     input {
            //         width: 25px !important;
            //     }
            // }
        }
    }

    .cx-accordion-body-number-item {
        // margin: 0 10px;
        font-weight: 800;
        font-size: $font-size-20;
    }

    .kyc-number {
        overflow: hidden;
    }
}

.cx-pay-container {
    // background-color: $dark-color-100;
    background-color: #0D6EFD;
    max-width: 400px;
    display: flex;
    padding: 10px 15px;
    border-radius: $border-radius-10;
    align-items: center;
    margin: 10px 0;
    box-shadow: 0px 2px 5px rgba($color: $black-color, $alpha: 0.1);
    margin: 0 auto;
    text-align: center;
    justify-content: space-between;
    opacity: 0.5;
    cursor: not-allowed;
    color: $white-color;

    .cx-pay-wrapper {
        img {
            width: 60px;
            margin-right: 10px;
        }
    }

    .cx-total-wrapper {
        p {
            justify-content: right;
            margin: 0;
            display: flex;
            align-items: center;

            span {
                margin-right: 5px;
                display: block;
            }
        }

        span {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                margin-right: 5px;
            }
        }
    }
}

.cx-pay-container-active {
    opacity: 1 !important;
    cursor: pointer !important;
}

// .selected-item {
//     background-color: $dark-selected-color;
// }

.cx-window-container {
    margin-top: 50px;
    margin-bottom: 100px;

    .cx-window-wrapper {
        box-shadow: 2px 2px 10px rgba($color: $dark-color, $alpha: 0.2);
        border-radius: $border-radius-20;

        .cx-window-left-side {
            @extend .cx--gray-background;
            overflow: hidden;
            color: $dark-color;

            .cx-window-body-container {
                padding: 25px;
                min-height: calc(100vh - 400px);

                .cx-company-name-wrapper {
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 25px;

                    img {
                        height: 25px;
                    }

                    p {
                        margin: 0;
                        font-size: $font-size-22;
                        margin-left: 20px;
                        color: $primary-color;
                        font-weight: 500;
                    }
                }

                .cx-order-top-info {
                    .cx-order-top-info-list {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-evenly;

                        .cx-order-top-info-item {
                            justify-content: center;
                            background-color: $white-color;
                            border: 1px solid $dark-color-200;
                            padding: 10px 15px;
                            display: flex;
                            align-items: center;
                            font-size: $font-size-14;
                            flex: 1;
                            white-space: nowrap;
                            font-style: italic;

                            span {
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                margin-left: 10px;
                                font-style: normal;

                                &:first-child {
                                    margin-right: 10px;
                                }
                            }

                            img {
                                max-width: 25px;
                                margin-right: 5px;
                            }
                        }

                        @media screen and (min-width: 992px) {
                            .cx-order-top-info-item {
                                justify-content: start;
                            }
                        }
                    }
                }

                .cx-window-body {
                    .cx-paymant-description {
                        font-size: $font-size-16;
                        opacity: 0.8;
                        margin-top: 0;
                        margin-bottom: 20px;
                        display: block;
                    }

                    .cx-order-info {
                        .cx-order-info-list {
                            padding: 0;
                            list-style: none;
                            max-width: 250px;

                            .order-info-list-item {
                                display: flex;
                                align-items: center;
                                padding: 5px 0;
                                font-weight: 400;
                                justify-content: space-between;
                                font-size: $font-size-18;

                                .cx-order-list-item-title {
                                    font-style: italic;
                                }

                                .cx-order-list-item-value {
                                    display: flex;
                                    align-items: center;
                                    font-weight: 500;
                                    color: $primary-color;


                                }

                                img {
                                    max-width: 20px;
                                    margin-right: 10px;
                                }

                                &:last-child {
                                    .cx-order-list-item-title {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }

                    .cx-orders-list-container {
                        // display: none;

                        strong {
                            font-size: $font-size-18;
                            margin-bottom: 10px;
                            display: block;
                        }



                        .cx-show-all {
                            text-align: right;
                            width: 100%;

                            a {
                                font-size: $font-size-14;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .cx-window-right-side {
            @extend .cx--primary-background;
            overflow: hidden;

            .invalid-field {
                border: 1px solid $danger-color;
                border-radius: $border-radius-10;
            }

            .valid-field {
                border: 2px solid $success-color;
                border-radius: $border-radius-10;
            }

            .accordion-item {
                border: none;

                .accordion-button {
                    background: $background;
                    color: $white-color;
                    font-size: $font-size-18;
                    font-weight: 600;
                    box-shadow: initial;

                    img {
                        margin-right: 10px;
                    }

                    &::after {
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4yOSIgaGVpZ2h0PSI2LjE3OCIgdmlld0JveD0iMCAwIDEyLjI5IDYuMTc4Ij4NCiAgPHBhdGggaWQ9IlBhdGhfMTYyIiBkYXRhLW5hbWU9IlBhdGggMTYyIiBkPSJNMTMuMzMzLDEyLjksNy40LDguMiwxLjUxOCwxMi45YTEuMTIyLDEuMTIyLDAsMCwwLS4yMzMsMWMuMTE2LjQuMzQ5LjUuNTgyLjRsNS4xOC00LjJhLjQ4Mi40ODIsMCwwLDEsLjcsMGw1LjE4LDQuMmMuMjMzLjIuNDY2LDAsLjU4Mi0uNEExLjExOSwxLjExOSwwLDAsMCwxMy4zMzMsMTIuOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjI2NCAtOC4yKSIgZmlsbD0iI2ZiZmJmYiIvPg0KPC9zdmc+DQo=');
                    }
                }

                .accordion-collapse {
                    background-color: $primary-color;

                    .accordion-body {
                        color: $white-color;
                        padding: 1.25rem 1.5rem;

                        .cx-number-btn {
                            background-color: $white-color;
                            color: $primary-color;
                            font-weight: 500;
                        }

                        // .selected-item {
                        //     background-color: $dark-selected-color;
                        // }

                        // .cx-accordion-body-list {
                        //     list-style: none;
                        //     display: flex;
                        //     overflow: auto;
                        //     align-items: center;
                        //     padding: 0;
                        //     margin: 0;

                        //     .cx-accordion-list-item {
                        //         text-align: center;
                        //         flex: 1;
                        //         padding: 10px;
                        //         border-radius: $border-radius-10;
                        //         // cursor: pointer;
                        //         transition: 0.3s;
                        //         margin: 5px;
                        //         max-width: 120px;

                        //         img {
                        //             width: 40px;
                        //             margin-bottom: 10px;
                        //         }

                        //         .cx-accordion-list-item-name {
                        //             font-size: $font-size-12;
                        //             font-weight: 600;

                        //             span {
                        //                 display: block;
                        //                 font-weight: 100;
                        //             }
                        //         }

                        //         // &:hover {
                        //         //     background-color: $dark-selected-color;
                        //         // }
                        //     }

                        //     // .cx-accordion-body-number-item:not(:nth-child(5)) {
                        //     //     padding: 5px 5px;
                        //     //     margin: 3px;
                        //     //     background-color: $white-color;
                        //     //     border-radius: $border-radius-5;
                        //     //     box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 40%);

                        //     //     input {
                        //     //         border: none;
                        //     //         text-align: center;
                        //     //         width: 30px;
                        //     //         font-weight: 600;
                        //     //         font-size: $font-size-22;
                        //     //         color: $black-color;

                        //     //         &:focus {
                        //     //             outline: none;
                        //     //         }
                        //     //     }
                        //     // }

                        //     // .cx-accordion-body-number-item:nth-child(5) {
                        //     //     margin: 0 10px;
                        //     //     font-weight: 800;
                        //     //     font-size: $font-size-20;
                        //     // }

                        //     .cx-accordion-body-number-item {
                        //         padding: 5px;
                        //         margin: 3px;
                        //         // background-color: $white-color;
                        //         border-radius: $border-radius-5;
                        //         // box-shadow: inset 2px 2px 5px rgba($color: $black-color, $alpha: 0.3);

                        //         div {
                        //             input {
                        //                 // border: none;
                        //                 text-align: center;
                        //                 width: 35px !important;
                        //                 font-weight: 600;
                        //                 font-size: $font-size-22;
                        //                 color: $black-color;
                        //                 border-radius: $border-radius-5;



                        //                 box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
                        //                 border: 1px solid #ced4da;

                        //                 &:focus {
                        //                     outline: none;
                        //                 }
                        //             }

                        //             // @media screen and (max-width: 575.98px) {
                        //             //     input {
                        //             //         width: 25px !important;
                        //             //     }
                        //             // }
                        //         }
                        //     }

                        //     .cx-accordion-body-number-item {
                        //         // margin: 0 10px;
                        //         font-weight: 800;
                        //         font-size: $font-size-20;
                        //     }

                        //     .kyc-number {
                        //         overflow: hidden;
                        //     }
                        // }
                    }
                }
            }





            /* width */
            ::-webkit-scrollbar {
                height: 5px;
                width: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: $dark-color-100;
                border-radius: 10px;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: $secondary-color;
            }
        }
    }

    @media screen and (min-width: 992px) {
        .cx-window-wrapper {
            display: flex;

            .cx-window-right-side,
            .cx-window-left-side {
                flex: 1;
            }
        }

    }
}


.cx-modal-container {
    // position: relative;
    // display: none;

    .cx-loader-wrapper {
        width: fit-content;
        text-align: center;
        margin: 15px auto;
    }

    .cx-modal-wrapper {
        // position: fixed;
        // left: 50%;
        // transform: translateX(-50%);
        // border-radius: $border-radius-30;
        // padding: 30px;
        // box-shadow: 2px 2px 10px rgba($color: $black-color, $alpha: 0.2);
        // z-index: 100;

        .cx-merchant-logo {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0%);

            div {
                width: 120px;
                height: 50px;
                margin-top: 10px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .cx-modal-body-container {
            padding: 15px;

            .cx-modal-body-wrapper {
                text-align: center !important;
                margin: 0 auto;

                .cx-window-title {
                    margin-bottom: 10px;
                    display: block;
                }

                strong {
                    font-size: $font-size-20;
                }

                .cx-modal-main-title {

                    strong,
                    span {
                        font-size: $font-size-12;
                    }
                }

                .cx--modal-icon {
                    margin: 25px 0;

                    img {
                        width: 50px;
                    }
                }

                .cx-modal-body-info {
                    max-width: 250px;
                    margin: 15px auto;
                    display: flex;
                    justify-content: space-between;
                    font-size: $font-size-16;

                    span {
                        margin: 0 2px;
                    }

                    span,
                    strong {
                        font-size: $font-size-16;
                    }
                }

                button {
                    margin-top: 20px;
                }

                .cx-link {
                    margin-top: 20px;
                    display: block;
                }

                .cx-modal-body-small-title {
                    font-size: $font-size-14;

                    span {
                        color: green;
                    }
                }

                .cx-spiner-wrapper {
                    .cx-spiner {
                        width: 100%;
                        max-width: 35px;
                        margin: 15px 0;
                    }

                    .cx-spiner-text {
                        font-size: $font-size-14;
                    }
                }
            }
        }
    }

    .cx-small-modal {
        width: 350px !important;
        top: 200px !important;
    }
}

.cx-modal-container.modal-show {
    display: block;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $black-color;
        position: fixed;
        top: 0;
        opacity: 0.8;
        left: 0;
        z-index: 10;
    }
}


.loader {
    border: 16px solid $dark-color-200;
    /* Light grey */
    border-top: 16px solid $secondary-color;
    /* Blue */
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: spin 2s linear infinite;
}

.spiner-20 {
    height: 20px;
    width: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cx-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #ebf0f4;

    @media (max-width: 991.98px) {
        position: initial;
    }

    .cx-fotter-options {
        display: flex;
        margin: 0;
        list-style: none;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 20px;

        a {
            font-size: $font-size-16;
            color: $dark-color;
            font-weight: 500;
            display: block;
            text-wrap: nowrap;

            &:hover {
                opacity: 0.8;
            }
        }

    }

    .powered {
        @media (max-width: 991.98px) {
            width: 100%;
            text-align: right;
        }
    }
}

@media (max-width: 991.98px) {
    // .cx-window-container {
    //     width: calc(100% - 30px);
    // }

    .cx-window-left-side {
        border-top-right-radius: $border-radius-20;
        border-top-left-radius: $border-radius-20;
    }

    .cx-window-right-side {
        border-bottom-right-radius: $border-radius-20;
        border-bottom-left-radius: $border-radius-20;
    }

    .accordion-body {
        .cx-number-btn {
            margin: 10px 0;
            width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .cx-window-container {
        margin-bottom: 100px;
    }

    .cx-window-left-side {
        border-top-left-radius: $border-radius-20;
        border-bottom-left-radius: $border-radius-20;
    }

    .cx-window-right-side {
        border-top-right-radius: $border-radius-20;
        border-bottom-right-radius: $border-radius-20;
    }

    .accordion-body {
        .cx-number-btn {
            margin: 0 10px;
        }
    }
}

.display_none {
    display: none;
}

.img_qr_code {
    background-color: $dark-color-100;
    padding: 20px 20px;
    font-size: small;

    img {
        margin-bottom: 15px;
        font-weight: bold;
    }
}


.cx-overflow-inherit {
    overflow: inherit !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.disabled-accordion {
    pointer-events: none;
    opacity: 0.9;
}

.cx-code-input {
    width: inherit !important;
}

.styles_blur__19vMK {
    top: 5px;
    border-radius: var(--crillex-br-5);
}

.error-border {
    border: 1px solid $danger-color !important;
}

.phone-number-input {
    padding-left: 30px !important;
}

.custom-phone-number-input-block {
    border-radius: 10px;
    height: 45px;

    .selected-flag {
        border-radius: 10px;
    }

    input {
        height: 100% !important;
        width: 100% !important;
        border-radius: 10px !important;
    }

    .flag-dropdown {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .selected-flag {
        border-radius: 10px !important;
    }

    .country-name {
        color: #6C757D;
    }
}

.cx-phone-number-block {
    position: relative;
    width: 100%;


    .plus {
        position: absolute;
        color: $black-color;
        font-size: $font-size-20;
        top: 50%;
        transform: translateY(-60%);
        left: 14px;
        line-height: 1;
        color: $dark-color;
        opacity: 0.7;
        margin: 0;
    }
}

.react-confirm-alert-title-block {
    display: flex;
    align-items: center;

    h3 {
        margin: 10px 0;
        text-align: start;
    }

    img {
        width: 25px;
        margin-right: 10px;
    }
}

.spinner-layer {
    width: 100%;
    height: 100%;
    position: fixed;
    // background-color: var(--crillex-background-color);
    background: rgba(255, 255, 255, 0.6);
    top: 0;
    z-index: 10;
    // opacity: 0.7;

    .sweet-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

body {
    overflow-y: scroll;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $dark-color-100;
}

body::-webkit-scrollbar {
    width: 6px;
    background-color: $dark-color-100
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    // background-color: #D62929;
    background-color: $primary-color-50;
}

.bold {
    font-weight: bold;
}

.mb-0 {
    margin-bottom: 0;
}

.cx-select__control {
    margin: 0;
    width: 100%;
    border-radius: $border-radius-10 !important;
    padding: 3px 10px 3px 0;
    border: none;

    &::placeholder {
        color: $black-color;
        opacity: 0.3;
        text-transform: initial;
    }

    &:focus {
        outline: none;
        box-shadow: none;
        // border: 1px solid rgba(16, 128, 232, 0.384);
    }

    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

.cx-select__placeholder {
    padding-left: 6px;
    // font-style: italic;
}

.transaction-error-message-block {
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: $white-color;
}

.react-confirm-alert-overlay {
    z-index: 10001 !important;
}

.cx-accordion-list-item {
    svg {
        width: 35px;
        height: 35px;
    }
}

.small-icon {
    svg {
        width: 25px;
        height: 25px;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.solana-connect-button {
    .wallet-adapter-dropdown {
        width: 100%;

        .wallet-adapter-button {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            user-select: none;
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: #fff;
                background-color: #0b5ed7;
                border-color: #0a58ca;
            }
        }
    }
}

.eye-block {
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}

.google-auth-block {
    margin-bottom: 30px;

    .alternative {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        .line {
            height: 1px;
            background-color: $border-color;
        }
    }
}

.blacklist-modal {
    .modal-content {
        background-color: $danger-color;
        color: $white-color;
    }
}



/* HTML: <div class="loader"></div> */
.veriff-loader {
    width: 60px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side, #212529 90%, #0000);
    background: var(--_g), var(--_g), var(--_g);
    background-size: 25% 25%;
    animation: l17 1s infinite;
}

@keyframes l17 {
    0% {
        background-position: 0% -100%, 50% -100%, 100% -100%
    }

    16.67% {
        background-position: 0% 50%, 50% -100%, 100% -100%
    }

    33.33% {
        background-position: 0% 50%, 50% 50%, 100% -100%
    }

    45%,
    55% {
        background-position: 0% 50%, 50% 50%, 100% 50%
    }

    66.67% {
        background-position: 0% 200%, 50% 50%, 100% 50%
    }

    83.33% {
        background-position: 0% 200%, 50% 200%, 100% 50%
    }

    100% {
        background-position: 0% 200%, 50% 200%, 100% 200%
    }
}

.veriff-disabled {
    pointer-events: none;
    opacity: 0.08;
}

.veriff-wait-block {
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 50px;
    width: 100%;
}

.cx-select__single-value {
    margin-left: 10px !important;
}