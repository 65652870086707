.animation-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.animation-logo svg {
  width: 300px;
  height: 300px;
}

.small-logo svg {
  width: 50px !important;
  height: 50px !important;
}/*# sourceMappingURL=logoAnimation.css.map */