body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

a {
  color: #000000;
}
a:hover {
  opacity: 0.5;
}

input[type=text],
input[type=password],
input[type=date],
input[type=tel],
input[type=email] {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #E5E5E5;
  width: 100%;
  background-color: #FFFFFF !important;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=tel]:focus,
input[type=email]:focus {
  box-shadow: none;
}

input[type=button],
input[type=submit],
input[type=reset] {
  padding: 10px 50px;
  background-color: #13C4FC;
  color: #FFFFFF;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.veriff-modal-select-option {
  border-radius: 10px;
  width: 100%;
  height: 46px;
}

.capitalize {
  text-transform: capitalize;
}

.word-break-break-word {
  word-break: break-all;
}

.cx-cancel-btn {
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
}
.cx-cancel-btn button {
  color: #FFFFFF;
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
}

.err {
  background-color: #FFFFFF;
  color: #B30505;
  font-size: large;
  padding: 20px 20px !important;
  text-align: center;
}

.cx--container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .cx--container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .cx--container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .cx--container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cx--container {
    max-width: 1140px;
  }
}
.cx--header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cx--header .cx--logo-wrapper {
  margin-top: 35px;
}
.cx--header .cx--logo-wrapper img {
  max-height: 35px;
}

.custom-header {
  background-color: #ebf0f4;
}
@media (max-width: 991.98px) {
  .custom-header {
    font-size: 14px;
  }
}

.cx--gray-background, .cx-window-container .cx-window-wrapper .cx-window-left-side {
  background-color: #f7f8fa;
}

.cx--primary-background, .cx-window-container .cx-window-wrapper .cx-window-right-side {
  background-color: #0533B3;
}

.cx--dark-background {
  background-color: #222F3E;
}

.cx--white-background {
  background-color: #FFFFFF;
}

.cx-accordion-body-list {
  list-style: none;
  display: flex;
  overflow: auto;
  align-items: center;
  padding: 0;
  margin: 0;
}
.cx-accordion-body-list .cx-accordion-list-item {
  text-align: center;
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s;
  margin: 5px;
  max-width: 120px;
}
.cx-accordion-body-list .cx-accordion-list-item img {
  width: 40px;
  margin-bottom: 10px;
}
.cx-accordion-body-list .cx-accordion-list-item .cx-accordion-list-item-name {
  font-size: 12px;
  font-weight: 600;
}
.cx-accordion-body-list .cx-accordion-list-item .cx-accordion-list-item-name span {
  display: block;
  font-weight: 100;
}
.cx-accordion-body-list .cx-accordion-body-number-item {
  border-radius: 5px;
}
.cx-accordion-body-list .cx-accordion-body-number-item div {
  flex-wrap: wrap;
}
.cx-accordion-body-list .cx-accordion-body-number-item div input {
  text-align: center;
  width: 35px !important;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ced4da;
}
.cx-accordion-body-list .cx-accordion-body-number-item div input:focus {
  outline: none;
}
.cx-accordion-body-list .cx-accordion-body-number-item {
  font-weight: 800;
  font-size: 20px;
}
.cx-accordion-body-list .kyc-number {
  overflow: hidden;
}

.cx-pay-container {
  background-color: #0D6EFD;
  max-width: 400px;
  display: flex;
  padding: 10px 15px;
  border-radius: 10px;
  align-items: center;
  margin: 10px 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  text-align: center;
  justify-content: space-between;
  opacity: 0.5;
  cursor: not-allowed;
  color: #FFFFFF;
}
.cx-pay-container .cx-pay-wrapper img {
  width: 60px;
  margin-right: 10px;
}
.cx-pay-container .cx-total-wrapper p {
  justify-content: right;
  margin: 0;
  display: flex;
  align-items: center;
}
.cx-pay-container .cx-total-wrapper p span {
  margin-right: 5px;
  display: block;
}
.cx-pay-container .cx-total-wrapper span {
  display: flex;
  align-items: center;
}
.cx-pay-container .cx-total-wrapper span img {
  width: 20px;
  margin-right: 5px;
}

.cx-pay-container-active {
  opacity: 1 !important;
  cursor: pointer !important;
}

.cx-window-container {
  margin-top: 50px;
  margin-bottom: 100px;
}
.cx-window-container .cx-window-wrapper {
  box-shadow: 2px 2px 10px rgba(34, 47, 62, 0.2);
  border-radius: 20px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side {
  overflow: hidden;
  color: #222F3E;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container {
  padding: 25px;
  min-height: calc(100vh - 400px);
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-company-name-wrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-company-name-wrapper img {
  height: 25px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-company-name-wrapper p {
  margin: 0;
  font-size: 22px;
  margin-left: 20px;
  color: #0533B3;
  font-weight: 500;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list .cx-order-top-info-item {
  justify-content: center;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
  flex: 1;
  white-space: nowrap;
  font-style: italic;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list .cx-order-top-info-item span {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 10px;
  font-style: normal;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list .cx-order-top-info-item span:first-child {
  margin-right: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list .cx-order-top-info-item img {
  max-width: 25px;
  margin-right: 5px;
}
@media screen and (min-width: 992px) {
  .cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-order-top-info .cx-order-top-info-list .cx-order-top-info-item {
    justify-content: start;
  }
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-paymant-description {
  font-size: 16px;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 20px;
  display: block;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list {
  padding: 0;
  list-style: none;
  max-width: 250px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list .order-info-list-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-weight: 400;
  justify-content: space-between;
  font-size: 18px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list .order-info-list-item .cx-order-list-item-title {
  font-style: italic;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list .order-info-list-item .cx-order-list-item-value {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #0533B3;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list .order-info-list-item img {
  max-width: 20px;
  margin-right: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-order-info .cx-order-info-list .order-info-list-item:last-child .cx-order-list-item-title {
  font-weight: 700;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-orders-list-container strong {
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-orders-list-container .cx-show-all {
  text-align: right;
  width: 100%;
}
.cx-window-container .cx-window-wrapper .cx-window-left-side .cx-window-body-container .cx-window-body .cx-orders-list-container .cx-show-all a {
  font-size: 15px;
  text-decoration: underline;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side {
  overflow: hidden;
  /* width */
  /* Track */
  /* Handle */
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .invalid-field {
  border: 1px solid #B30505;
  border-radius: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .valid-field {
  border: 2px solid #05B32E;
  border-radius: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item {
  border: none;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-button {
  background: linear-gradient(0deg, rgb(5, 51, 179) 0%, rgb(19, 196, 252) 100%);
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  box-shadow: initial;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-button img {
  margin-right: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4yOSIgaGVpZ2h0PSI2LjE3OCIgdmlld0JveD0iMCAwIDEyLjI5IDYuMTc4Ij4NCiAgPHBhdGggaWQ9IlBhdGhfMTYyIiBkYXRhLW5hbWU9IlBhdGggMTYyIiBkPSJNMTMuMzMzLDEyLjksNy40LDguMiwxLjUxOCwxMi45YTEuMTIyLDEuMTIyLDAsMCwwLS4yMzMsMWMuMTE2LjQuMzQ5LjUuNTgyLjRsNS4xOC00LjJhLjQ4Mi40ODIsMCwwLDEsLjcsMGw1LjE4LDQuMmMuMjMzLjIuNDY2LDAsLjU4Mi0uNEExLjExOSwxLjExOSwwLDAsMCwxMy4zMzMsMTIuOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjI2NCAtOC4yKSIgZmlsbD0iI2ZiZmJmYiIvPg0KPC9zdmc+DQo=");
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-collapse {
  background-color: #0533B3;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-collapse .accordion-body {
  color: #FFFFFF;
  padding: 1.25rem 1.5rem;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side .accordion-item .accordion-collapse .accordion-body .cx-number-btn {
  background-color: #FFFFFF;
  color: #0533B3;
  font-weight: 500;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side ::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side ::-webkit-scrollbar-track {
  background: #E5E5E5;
  border-radius: 10px;
}
.cx-window-container .cx-window-wrapper .cx-window-right-side ::-webkit-scrollbar-thumb {
  background: #13C4FC;
}
@media screen and (min-width: 992px) {
  .cx-window-container .cx-window-wrapper {
    display: flex;
  }
  .cx-window-container .cx-window-wrapper .cx-window-right-side,
  .cx-window-container .cx-window-wrapper .cx-window-left-side {
    flex: 1;
  }
}

.cx-modal-container .cx-loader-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 15px auto;
}
.cx-modal-container .cx-modal-wrapper .cx-merchant-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.cx-modal-container .cx-modal-wrapper .cx-merchant-logo div {
  width: 120px;
  height: 50px;
  margin-top: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container {
  padding: 15px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper {
  text-align: center !important;
  margin: 0 auto;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-window-title {
  margin-bottom: 10px;
  display: block;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper strong {
  font-size: 20px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-main-title strong,
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-main-title span {
  font-size: 12px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx--modal-icon {
  margin: 25px 0;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx--modal-icon img {
  width: 50px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-info {
  max-width: 250px;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-info span {
  margin: 0 2px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-info span,
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-info strong {
  font-size: 16px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper button {
  margin-top: 20px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-link {
  margin-top: 20px;
  display: block;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-small-title {
  font-size: 15px;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-modal-body-small-title span {
  color: green;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-spiner-wrapper .cx-spiner {
  width: 100%;
  max-width: 35px;
  margin: 15px 0;
}
.cx-modal-container .cx-modal-wrapper .cx-modal-body-container .cx-modal-body-wrapper .cx-spiner-wrapper .cx-spiner-text {
  font-size: 15px;
}
.cx-modal-container .cx-small-modal {
  width: 350px !important;
  top: 200px !important;
}

.cx-modal-container.modal-show {
  display: block;
}
.cx-modal-container.modal-show::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: fixed;
  top: 0;
  opacity: 0.8;
  left: 0;
  z-index: 10;
}

.loader {
  border: 16px solid #CCCCCC;
  /* Light grey */
  border-top: 16px solid #13C4FC;
  /* Blue */
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

.spiner-20 {
  height: 20px;
  width: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cx-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #ebf0f4;
}
@media (max-width: 991.98px) {
  .cx-footer {
    position: initial;
  }
}
.cx-footer .cx-fotter-options {
  display: flex;
  margin: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 20px;
}
.cx-footer .cx-fotter-options a {
  font-size: 16px;
  color: #222F3E;
  font-weight: 500;
  display: block;
  text-wrap: nowrap;
}
.cx-footer .cx-fotter-options a:hover {
  opacity: 0.8;
}
@media (max-width: 991.98px) {
  .cx-footer .powered {
    width: 100%;
    text-align: right;
  }
}

@media (max-width: 991.98px) {
  .cx-window-left-side {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .cx-window-right-side {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .accordion-body .cx-number-btn {
    margin: 10px 0;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .cx-window-container {
    margin-bottom: 100px;
  }
  .cx-window-left-side {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .cx-window-right-side {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion-body .cx-number-btn {
    margin: 0 10px;
  }
}
.display_none {
  display: none;
}

.img_qr_code {
  background-color: #E5E5E5;
  padding: 20px 20px;
  font-size: small;
}
.img_qr_code img {
  margin-bottom: 15px;
  font-weight: bold;
}

.cx-overflow-inherit {
  overflow: inherit !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.disabled-accordion {
  pointer-events: none;
  opacity: 0.9;
}

.cx-code-input {
  width: inherit !important;
}

.styles_blur__19vMK {
  top: 5px;
  border-radius: var(--crillex-br-5);
}

.error-border {
  border: 1px solid #B30505 !important;
}

.phone-number-input {
  padding-left: 30px !important;
}

.custom-phone-number-input-block {
  border-radius: 10px;
  height: 45px;
}
.custom-phone-number-input-block .selected-flag {
  border-radius: 10px;
}
.custom-phone-number-input-block input {
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px !important;
}
.custom-phone-number-input-block .flag-dropdown {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-phone-number-input-block .selected-flag {
  border-radius: 10px !important;
}
.custom-phone-number-input-block .country-name {
  color: #6C757D;
}

.cx-phone-number-block {
  position: relative;
  width: 100%;
}
.cx-phone-number-block .plus {
  position: absolute;
  color: #000000;
  font-size: 20px;
  top: 50%;
  transform: translateY(-60%);
  left: 14px;
  line-height: 1;
  color: #222F3E;
  opacity: 0.7;
  margin: 0;
}

.react-confirm-alert-title-block {
  display: flex;
  align-items: center;
}
.react-confirm-alert-title-block h3 {
  margin: 10px 0;
  text-align: start;
}
.react-confirm-alert-title-block img {
  width: 25px;
  margin-right: 10px;
}

.spinner-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  z-index: 10;
}
.spinner-layer .sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #E5E5E5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #E5E5E5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #E3EDFA;
}

.bold {
  font-weight: bold;
}

.mb-0 {
  margin-bottom: 0;
}

.cx-select__control {
  margin: 0;
  width: 100%;
  border-radius: 10px !important;
  padding: 3px 10px 3px 0;
  border: none;
}
.cx-select__control::-moz-placeholder {
  color: #000000;
  opacity: 0.3;
  text-transform: initial;
}
.cx-select__control::placeholder {
  color: #000000;
  opacity: 0.3;
  text-transform: initial;
}
.cx-select__control:focus {
  outline: none;
  box-shadow: none;
}
.cx-select__control:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cx-select__placeholder {
  padding-left: 6px;
}

.transaction-error-message-block {
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.react-confirm-alert-overlay {
  z-index: 10001 !important;
}

.cx-accordion-list-item svg {
  width: 35px;
  height: 35px;
}

.small-icon svg {
  width: 25px;
  height: 25px;
}

.pointer-events-none {
  pointer-events: none;
}

.solana-connect-button .wallet-adapter-dropdown {
  width: 100%;
}
.solana-connect-button .wallet-adapter-dropdown .wallet-adapter-button {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solana-connect-button .wallet-adapter-dropdown .wallet-adapter-button:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.eye-block {
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 1s linear infinite;
}

.google-auth-block {
  margin-bottom: 30px;
}
.google-auth-block .alternative {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.google-auth-block .alternative .line {
  height: 1px;
  background-color: #DEE2E6;
}

.blacklist-modal .modal-content {
  background-color: #B30505;
  color: #FFFFFF;
}

/* HTML: <div class="loader"></div> */
.veriff-loader {
  width: 60px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #212529 90%, #0000);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 25% 25%;
  animation: l17 1s infinite;
}

@keyframes l17 {
  0% {
    background-position: 0% -100%, 50% -100%, 100% -100%;
  }
  16.67% {
    background-position: 0% 50%, 50% -100%, 100% -100%;
  }
  33.33% {
    background-position: 0% 50%, 50% 50%, 100% -100%;
  }
  45%, 55% {
    background-position: 0% 50%, 50% 50%, 100% 50%;
  }
  66.67% {
    background-position: 0% 200%, 50% 50%, 100% 50%;
  }
  83.33% {
    background-position: 0% 200%, 50% 200%, 100% 50%;
  }
  100% {
    background-position: 0% 200%, 50% 200%, 100% 200%;
  }
}
.veriff-disabled {
  pointer-events: none;
  opacity: 0.08;
}

.veriff-wait-block {
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 50px;
  width: 100%;
}

.cx-select__single-value {
  margin-left: 10px !important;
}/*# sourceMappingURL=styles.css.map */